@import url('http://fonts.cdnfonts.com/css/monaco');

body {
  background: #272a36;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

*, ::after, ::before {
  box-sizing: border-box;
}

*:focus {
  outline: 0;
}

.App {
  height: 100vh;
  display: flex;
}

aside {
  display: flex;
  align-items: center;
  flex-direction: column;
  color: #6D8A88;
}

span, strong, a {
  font-family: 'Monaco', Consolas, Menlo, Courier, monospace;
  font-size: 16px;
}

strong, a {
  margin: 2px;
}

span { 
  margin: 6px;
  display: flex;
}

main {
  margin-left: 35px;
}

.number {
  margin: 4px 0px 0px 14px;
  left: 0;
  width: 30px;
  font-family: 'Monaco', Consolas, Menlo, Courier, monospace;
  font-size: 16px;

  border: 1px solid #272a36;
}

div .block {
  margin: 6px 0px 6px 20px;
}

.commentLine {
  color: #6277a4;
}

.default {
  color: #FFFFFF;
}

.key {
  color: #ff79c6;
}

.string {
  color: #f1fa8c;
}

.integer {
  color: #bd93f9;
}

.boolean {
  color: #bd93f9;
}